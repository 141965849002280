<template>
  <div class="majorCard">
    <div class="imgShow" @click="toIntroduce">
      <div v-if="showCoven">
        <div class="hasC">
          <div class="hasC-left">
            <img :src="item.cooperative_university.logo" alt="">
          </div>
        </div>
      </div>
      <div v-else>
        <div class="noC">
          <img class="img1" :src="item.school.school_logo" alt="">
        </div>
      </div>
    </div>
    <div class="content" @click="toIntroduce">
      <p>{{ item.name_chs }}</p>
      <p>{{ item.school.name_chs }}</p>
      <p>{{ item.subject_level.name }}:{{ item.subject_duration }}月</p>
      <p style="width: 30%;height: 2px;background-color: #007DD1;margin-top: 1.5vh"></p>
    </div>
    <div class="foot">
      <div class="foot-left">
        <p style="font-size: 12px;opacity: .5;">学费</p>
        <p>人民币:¥{{ myMoney }}</p>
      </div>
      <div class="foot-right">
        <div style="width: 2.5vw;height: 2.5vw" @click="dialogFormVisible = true" class="myImg">
          <img  width="100%" height="100%" src="../../assets/myImage/info2.png" alt="">
        </div>
      </div>
    </div>




    <el-dialog width="40%" style="text-align: center;font-weight: 600;"  title="获取留学方案" :visible.sync="dialogFormVisible">
      <b-form class="form-body" @submit="onSubmit">
        <div style="text-align: center" v-if="submit">
          <b-form-group class="form-field" id="form-name">
            <div></div>
            <b-form-input
                class="myForm"
                id="form-name-input"
                v-model="form.name"
                placeholder="请填写您的姓名"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="form-field" id="form-wechat">
            <b-form-input
                class="myForm"
                id="form-wechat-input"
                v-model="form.wechat"
                placeholder="您的微信号"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="form-field" id="form-phone">
            <b-form-input
                class="myForm"
                id="form-phone-input"
                v-model="form.phone"
                placeholder="您的手机号"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="form-field" id="form-highest_qualification">
            <b-form-select
                class="myForm"
                id="form-stage-input"
                v-model="form.highest_qualification"
                :options="highest_qualification"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="form-field" id="form-budget">
            <b-form-select
                class="myForm"
                id="form-budget-input"
                v-model="form.budget"
                :options="budget"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="form-field" id="form-contact_time">
            <b-form-select
                class="myForm"
                id="form-contact_time"
                v-model="form.contact_time"
                :options="contact_time"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="form-field" id="form-estimated_date">
            <b-form-select
                class="myForm"
                id="form-estimate-time-input"
                v-model="form.estimated_date"
                :options="estimated_date"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="form-field" id="form-requirement">
            <b-form-textarea
                class="myForm"
                id="form-requirement"
                v-model="form.requirement"
                placeholder="您的留学需求"
                required
            ></b-form-textarea>
          </b-form-group>

          <div class="btn-wrapper">
            <button class="myBtn" style="border: none" type="submit">提交</button>
          </div>
        </div>

        <div class="col-12 order-1 order-lg-2" v-else>
          <h3 style="color: black; text-align: center; padding-top: 30px">正在提交</h3>
          <div style="padding-top: 70px">
            <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="truck" animation="cylon" font-scale="4" shift-v="0"></b-icon>
            <b-icon style="
					color: black;
					display: block;
					margin-left: auto;
					margin-right: auto;
				" icon="three-dots" animation="cylon" font-scale="4"></b-icon>
          </div>
        </div>
      </b-form>
    </el-dialog>

<!--    <el-dialog-->
<!--        title="提示"-->
<!--        :visible.sync="dialogVisible"-->
<!--        width="30%">-->
<!--      <span>提交成功，近期会有人与您联系</span>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <button class="myBtn" style="border: none;border-radius: 0" @click="dialogVisible = false">确 定</button>-->
<!--  </span>-->
<!--    </el-dialog>-->
    <el-dialog
        title="提交成功"
        style="padding: 0 20px"
        :visible.sync="dialogVisible"
        width="30%">
      <div class="gwInfo">
        <div class="gw-left">
          <img width="60" height="60" src="../../assets/myImage/成功.png" alt="">
        </div>
        <div class="text">亲，我们已经收到您的信息！专业的顾问老师会火速与您联系～您也可以添加微信：<strong>EduSg11</strong> 或 <strong>扫码直接咨询我们的老师！</strong></div>
        <div class="er">
          <img width="210" height="200" src="../../assets/myImage/guwen.jpg" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <button class="bt"  @click="dialogVisible = false">确 定</button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import img1 from '../../assets/myImage/image1.png';
import img2 from '../../assets/myImage/image2.png'
import ConsultantForm from "@/components/ConsultantForm";
import axios from "axios";
import query from "vue-resource/src/url/query";
export default {
  name: "majorCard",
  components:{
    ConsultantForm
  },
  props:['item'],
  data(){
    return{
      showCoven:this.item.has_cooper_university,
      img1,
      img2,
      myMoney:'',
      dialogVisible:false,
      form: {
        name: '',
        wechat: '',
        estimated_date: null,
        requirement: '',
        budget:null,
        phone:'',
        contact_time:null,
        highest_qualification:null,
      },
      highest_qualification: [
        { value: null, text: "选择您的教育程度", disabled: true },
        { value: "幼儿园", text: "幼儿园" },
        { value: "小学", text: "小学" },
        { value: "中学", text: "中学" },
        { value: "高中", text: "高中" },
        { value: "大学", text: "大学" },
        { value: "研究生", text: "研究生" },
        { value: "其他学历", text: "其他学历" },
      ],
      estimated_date: [
        { value: null, text: "选择您的计划留学时间", disabled: true },
        { value: "三个月内", text: "三个月内" },
        { value: "六个月内", text: "六个月内" },
        { value: "一年内", text: "一年内" },
        { value: "明年、之后", text: "明年、之后" },
      ],
      budget : [
        { value: null, text: "选择您的计划留学预算", disabled: true },
        { value: '10万-20万人民币/年', text: '10万-20万人民币/年' },
        { value: '20-30万人民币/年', text: '20-30万人民币/年' },
        { value: '无指定要求', text: '无指定要求' },
      ],
      contact_time:[
        { value: null, text: "选择您的联系时间", disabled: true },
        { value: '上午9-12点', text: '上午9-12点' },
        { value: '下午12-3点', text: '下午12-3点' },
        { value: '下午3-6点', text: '下午3-6点' },
        { value: '下午6点以后', text: '下午6点以后' },
        { value: '任意时间均可', text: '任意时间均可' }
      ],
      submit:true,
      dialogFormVisible:false,
      formLabelWidth: '20vw'
    }
  },
  created() {
    if (this.item.subject_fee * 5 < 10000){
      this.myMoney = this.item.subject_fee * 5 + '元'
    }else {
      this.myMoney =( this.item.subject_fee * 5/10000).toFixed(1) + '万'
    }
  },
  methods: {
    toIntroduce(){
      this.$router.push({path:'/majorIntroduce',query:{item:JSON.stringify(this.item)}})
    },
    onSubmit(event) {
      event.preventDefault()
      this.submit = false
      axios({
        method:'post',
        url:"https://158-liuxue-p.study65.com/api/postStudy65WebStudentEnquiry/",
        headers: {
          'content-type': 'application/json',
          'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
        },
        data: {
          ...this.form
        }
      }).then((res) => {
        console.log(res)
        this.dialogVisible = true//提交完成后
        this.submit = true
        this.dialogFormVisible = false
        this.form = {
              name: '',
              wechat: '',
              estimated_date: null,
              requirement: '',
              budget:null,
              phone:'',
              contact_time:null,
              highest_qualification:null,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
*{
  margin: 0;
  padding: 0;
}
.form-body{
  border: none!important;
  box-shadow: none!important;
}
.bt{
  background-color: #007DD1;
  border: none;
  width: 4vw;
  height: 2vw;
  color: white;
  border-radius: 7px;
}
.gwInfo{
  margin-top: 0;
  text-align: center;
}
.text{
  margin-top: .5vw;
}
.gw{
  display: flex;
  justify-content: space-between;
  height: 14vw;
  margin-top: 1vw;
  padding: 0 1vw 2vw;

}
.er{
  margin-top: .7vw;
}

.imgShow{
  //height: 15vh;
}
.myForm{
  height: 3vw;
  padding-left: 1vw;
}
#form-requirement{
  height: 6vw;

}
.form-field{
  width: 25vw;
  margin: 1.5vw auto 1.5vw;
}
.majorCard{
  position: relative;
  width: 18vw;
  padding-bottom: 1vw;
  //height: 37vh;
  cursor: pointer;
  .majorImg2{
    position: absolute;
    height: 3.3vw;
    width: 5.5vw;
    top: 5%;
    img{
      border-radius: 0 20px 20px 0;
      width: 100%;
      height: 100%;
    }
  }
  .majorImg{
    //height: 15vh;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content{
    padding-left: 1vw;
    p:nth-child(1){
      opacity: .5;
      margin-top: .8vw;
      font-size: 12px;
    }
    p:nth-child(2){
      margin-top: .5vw;
    }
  }
  .foot{
    padding-left: 1vw;
    display: flex;
    margin-top: .8vw;
    .foot-left{
      width: 60%;
    }
    .foot-right{
      width: 40%;
      padding-left: 3vw;
      .myImg{
        height: 2.5vw;
        width: 2.5vw;
        img{
          height: 100%;
          width: 100%;
        }

      }
    }
  }
}
.hasC{
  .hasC-left{
    width: 100%;
    height: 7.5vw;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.noC{
  background-image: url("../../assets/myImage/image2.png");
  background-position: center;
  height: 7.5vw;
  width: 100%;
  .img1{
    background-color: white;
    border-radius: 0 20px 20px 0;
    position: absolute;
    height: 25%;
    width: 40%;
    top: 2vh;
    left: 0;
  }
}
</style>
